import React from 'react';
import axios from 'axios';
import Helmet from 'react-helmet'
import { Link, withPrefix, graphql } from 'gatsby'
//import get from 'lodash/get'
//import Img from 'gatsby-image'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
//import Icon from 'components/icon'
import '../css/bootstrap.min.css'
import '../css/jquery-ui.css'
import '../css/animate.css'
import '../css/fontawesome-all.min.css'
import '../css/magnific-popup.css'
import '../css/style.css'
import '../css/stellarnav.min.css'
import '../css/responsive.css'

import logo from '../img/instar_logo_gradient_white_text.svg'

//const statsURL = '/.netlify/functions/stats';
//const rewardURL = '/.netlify/functions/reward';
class Main extends React.Component {
  state = {
      stats : {},
      reward: {
        yearly: 0
      }
  }

  componentDidMount() {
      /*
      fetch(statsURL)
      .then((response) => response.json())
      .then(stats => {
          this.setState({ stats: stats });
      });

      fetch(rewardURL)
      .then((response) => response.json())
      .then(reward => {
          this.setState({ reward: reward });
      });
      */
  }

  render() {
    const { location, data } = this.props
    return (
      <Layout location={location}>
        <Helmet>
          <script async src={withPrefix('jquery-3.3.1.min.js')} />
          <script async src={withPrefix('jquery-ui.js')} />
          <script async src={withPrefix('wow.min.js')} type="text/javascript" />
          <script async src={withPrefix('bootstrap.min.js')} />
          {/*<script async src={withPrefix('countdown.js')} type="text/javascript"/>*/}
          <script async src={withPrefix('html5shiv.js')} />
          <script async src={withPrefix('html5shiv-printshiv.js')} />
          {/*<script async src={withPrefix('jquery.counterup.min.js')} type="text/javascript" />*/}
          <script async src={withPrefix('jquery.magnific-popup.min.js')} />
          <script async src={withPrefix('jquery.scrollUp.js')} />
          <script async src={withPrefix('jquery.waypoints.min.js')} />
          {/*<script async src={withPrefix('owl.carousel.min.js')} type="text/javascript" />*/}
          <script async src={withPrefix('popper.min.js')} />
          <script async src={withPrefix('stellarnav.min.js')} />
          <script async src={withPrefix('theme.js')} type="text/javascript" />
        </Helmet>
        <Meta site={siteMetadata} title="InStar" />
        <div>
          {/*<div id="preloader"></div>*/}
          <header>
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-sm-6 col-6">
                  <div className="logo">
                    <a href="#_top" className="smoothscroll">
                      <img src={logo} style={{ maxHeight: '74px' }} />
                    </a>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-12 col-12 align-self-center">
                  <div className="main-menu">
                    <div className="stellarnav">
                      <nav className="menu-list">
                        <ul>
                          <li>
                            <a href="#_centers" className="smoothscroll">
                              Data centers
                            </a>
                          </li>
                          <li>
                            <a href="#_roadmap" className="smoothscroll">
                              roadmap
                            </a>
                          </li>
                          <li>
                            <a href="#_team" className="smoothscroll">
                              team
                            </a>
                          </li>
                          <li>
                            <a href="#_questions" className="smoothscroll">
                              FAQ
                            </a>
                          </li>
                          <li>
                            <Link to="/shareholders/">Investor Relations</Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 d-none d-lg-block">
                  <div className="ht-buy-token">
                    <a href="#_eu" className="smoothscroll">
                      <img
                        src="../img/ue_logo.png"
                        style={{ maxHeight: '74px' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="hero-area" id="_top">
            <div className="hero-bg">
              <img src="img/home-one/hero-bg.png" alt="" />
            </div>
            <div className="hero-banner item-bounce">
              {/* <img src="img/home-one/hero-banner.png" alt="" /> */}
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-8 col-12">
                  <div className="hero-content">
                    <h2
                      className="title"
                      style={{ textTransform: 'uppercase' }}
                    >
                      InStar Energy Lab
                    </h2>
                    <hr />
                    <p className="text">
                      Protect the power grid against blackouts and
                      inefficiencies using large scale intelligent data centers. Our goal is to be a key player of demand
                      side response and power market
                    </p>                    
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="about-v1-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 d-flex align-items-center">
                  <div className="about-v1-content">
                    <h2 className="title center">Long term goal</h2>
             
                    <p id="text">
                      These positive and competitive conditions allow us to plan
                      deployment of containerized solutions for data
                      processing. Assuming large scale operations and low cost
                      energy sources it is highly stable profitable business
                      even though in the long term profit margins due to fierce
                      global competition have asymptotic tendencies.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <span id="_centers" style={{ marginBottom: '200px' }} />
          <section className="about-v2-area">
            {/*<div className="about-v2-bg">*/}
            {/*  <img src="../img/home-one/about-bg-total.png" alt=""/>*/}
            {/*</div>*/}
            <div className="container">
              <div className="section-title">
                <h1 className="title">
                  Container data centers with heat recovery
                </h1>
              </div>
              <h1 className="header text-center">Problems</h1>
              <div className="row">
                <div
                  className="col-lg-4 col-md-4 col-12"
                  style={{ paddingTop: '20px' }}
                >
                  <p id="text-3">
                    In 2018, 5 TWh of electricity from wind farms in Germany
                    could not be produced because the electricity grid was full
                    (turbines turned off)
                  </p>
                  <br />
                  <p id="text-3">
                    it's about the same as the annual electricity consumption by
                    2.5 MILLION households
                  </p>
                  <br />
                  <p id="text-3">
                    and this problem concerns not only Germany, but many other
                    countries with a large number of power plants based on
                    renewable energy sources.
                  </p>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-12"
                  style={{ paddingTop: '20px' }}
                >
                  <p id="text-3">
                    Although Poland is still investing in Energy SOURCES, the
                    THREAT of blackouts is still very real. This problem is even
                    more serious in less developed countries.
                  </p>
                  <br />
                  <p id="text-3">
                    PSE, the country's energy network operator, runs the DSR
                    (Demand Side Response) program, in which it pays large
                    consumers for their willingness to disconnect their
                    RECEIVERS whenever PSE requests it (when there is a risk of
                    possible network overload).
                  </p>
                  <br />
                  <p id="text-3">
                    The DSR program is used to stabilize the energy network, but
                    few companies can afford to suddenly stop all
                    energy-consuming processes (for a few hours) - in 2018, less
                    than 2% of the received power participated in the program.
                  </p>
                </div>
                <div
                  className="col-lg-4 col-md-4 col-12"
                  style={{ paddingTop: '20px' }}
                >
                  <p id="text-3">
                    It is estimated that computing is responsible for 1% of global energy
                    consumption. Some sources state that almost ¾ of this energy
                    comes from renewable sources
                  </p>
                  <br />
                  <p id="text-3">
                    the vast majority of the generated energy is emitted as heat
                    In the process of data processing in large data centers.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="about-v1-area" id="_about">
            <div className="container">
              <div className="section-title">
                <h1 className="title">Solution</h1>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                  <div className="about-v1-content">
                    <h2 className="title">Wouldn’t be awesome if you could:</h2>
                    <h3 className="title-2">
                      Allocate the surplus of energy produced by renewable
                      sources in a completely innovative way in data centers?
                    </h3>
                    <h3 className="title-2">
                      <b>Stabilize the energy network</b> at the same time?
                    </h3>
                    <h3 className="title-2">
                      <b>Re-use most of the heat</b> generated in a process of
                      large scale computing?
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="download-banner">
                    {/* <img
                      className="item-bounce"
                      src="img/home-one/download-banner.png"
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="about-v2-area">
            <div className="about-v2-bg">
              <img src="img/home-one/about-bg-total.png" alt="" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                  <div className="about-v2-content">
                    <h2 className="title">
                      What if all this could be achieved with one product?
                    </h2>
                    <p id="title-3">
                      Intelligent container data centers - remotely managed and
                      autonomous, ready to be connected to an energy source,
                      with proprietary AI-based software, adapted to DSR
                      program, with modules for heat recovery.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="about-v1-banner">
                    {/* <img
                      className="item-bounce"
                      src="img/home-one/about-tow-banner.png"
                      alt=""
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </section>

          

          <section className="why-area">
            <div className="why-bg">
              <img src="img/home-one/why-icon-bg.png" alt="" />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="why-left-content">
                    <h2 className="title">Why Choose Us?</h2>
                    <p className="text">
                      Intelligent blockchain container data centers with heat
                      recovery.
                    </p>
                    <p className="text-2">
                      We are working on fully autonomous, remotely manageable,
                      AI-powered container blockchain data centers with
                      efficient cooling and heat heat recovery.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12">
                  <div className="why-right-content">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="service-box service-1">
                          <span className="icon">
                            <img src="img/home-one/choose-icon-1.png" alt="" />
                          </span>
                          <h2 className="title">THE SOFTWARE.</h2>
                          <p className="text">
                            Proprietary software system for remote management
                            and auto-tuning based on machine learning/AI
                            algorithms.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="service-box service-1">
                          <span className="icon">
                            <i className="fas fa-lock"></i>
                          </span>

                          <h2 className="title">THE PATENT.</h2>
                          {/*<Icon title="500px" name="500px" />*/}

                          <p className="text">
                            Patent pending idea of blackout prevention using
                            container data centers.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="service-box service-1">
                          <span className="icon">
                            <img src="img/home-one/choose-icon-3.png" alt="" />
                          </span>
                          <h2 className="title">GOVERNMENT TRUST.</h2>
                          <p className="text">
                            Research and development center (CBR) with a EUR
                            1.4M grant from the Ministry of Investment and
                            Development
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12">
                        <div className="service-box service-1">
                          <span className="icon">
                            <img src="img/home-one/choose-icon-4.png" alt="" />
                          </span>
                          <h2 className="title">STRONG TEAM.</h2>
                          <p className="text">
                            Experienced team, IT oriented and experienced in different
                            fields of business.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          

          {/* <section className="faq-area" id="_questions">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                  <div className="faq-content">
                    <h2 className="title">Asked Questions</h2>
                    <div className="accordion" id="accordionExample">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <span className="icon">
                                <i className="fas fa-chevron-down"></i>
                              </span>{' '}
                              Potential markets
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <ul>
                              <li>
                                Producers of energy from renewable sources -
                                windmills, photovoltaics, hydroelectric plants,
                                geothermal and classic - nuclear, coal and gas
                                power plants
                              </li>
                              <li>
                                2019 is the tenth consecutive year when the
                                value of global investment in the RES exceeds $
                                200 billion
                              </li>
                              <li>
                                Large production plants that can achieve
                                synergies (e.g. sawmills, malt houses,
                                agricultural processing plants)
                              </li>
                              <li>
                                Especially large sawmills often have their
                                combined heat and power plants, which can be
                                used to power the container data center
                              </li>
                              <li>
                                Owners of large retail and service areas - a
                                typical large shopping mall has 2 MW boilers
                                installed and an electrical connection for
                                several hundred kilowatts of power
                              </li>                              
                              <li>
                                The global data center market is growing by an
                                average of 11% per year.
                              </li>                             
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <span className="icon">
                                <i className="fas fa-chevron-down"></i>
                              </span>{' '}
                              Business Model
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <ol>
                              <li>
                                sales of software for managing and optimizing
                                the work of a computing center (SaaS or box
                                model) !
                              </li>
                              <li>
                                integration and sale of container DATA CENTERS
                                (partnership with a hardware company) DIRECT
                                sales or rent model
                              </li>
                              <li>
                                advisory and consultancy in the field of
                                building and using container server
                                rooms
                              </li>
                              <li>
                                until the product launches - earning on
                                sales of computing power at the company’s R&D
                                center
                              </li>
                              <li>
                                after selling a sufficient number of mining
                                containers, we will be able to become an
                                aggregator of the DSR system and earn on
                                commissions from other entities participating in
                                the system
                              </li>
                            </ol>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <span className="icon">
                                <i className="fas fa-chevron-down"></i>
                              </span>{' '}
                              Our Advantages
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <ul>
                              <li>
                                We are building a research and development
                                center (CBR), for which we received a grant from
                                the Ministry of Investment and Development (the
                                value of the project exceeds CHF 2.5 million)
                              </li>
                              <li>
                                As part of the project, we buy and install over
                                1,000 server infrastructure
                              </li>
                              <li>
                                Tests are carried out on this equipment with the
                                goal of two things: developing a container
                                prototype and optimization software
                              </li>
                              <li>
                                A side effect of servers WORKING is significant
                                profits from computing power sales
                              </li>
                              <li>
                                We are already operating at a 1 MW solar farm
                                and considering moving to 1.5 MW biogas plant
                                with cheap energy
                              </li>
                              <li>
                                We also consider building a 1MW cogeneration
                                plant to significantly reduce the price of
                                electricity and explore potential synergies
                              </li>
                              <li>
                                We have letters of intent from well-known Polish
                                energy companies interested in implementing our
                                product
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="card">
                        <div className="card-header" id="headingFour">
                          <h2 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseFour"
                              aria-expanded="false"
                              aria-controls="collapseFour"
                            >
                              <span className="icon">
                                <i className="fas fa-chevron-down"></i>
                              </span>{' '}
                              Financial Model
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapseFour"
                          className="collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="card-body">
                            <ul>
                              <li>
                                In the first 2 years, the main source of revenue
                                will be computing power sales taking place in
                                parallel with the rest of the work of the R&D
                                center. Careful calculations indicate possible
                                ANNUAL profits between CHF 0.5 and 0.75 million
                              </li>
                              <li>
                                the analysis is based on OUR experience, own
                                studies and data
                              </li>
                              <li>
                                In the following years, we assume a decreasing
                                profit from computing power sharing (depreciation
                                of owned equipment) and a growing profit from
                                product sales (technology and containers)
                              </li>
                              <li>
                                Profitability is strongly dependent on the computing power sales and electricity prices
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="faq-banner">
                    <img
                      className="item-bounce"
                      src="img/home-one/faq-banner.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          {/*<section className="eu-area" id="_eu">*/}
          {/*  <div className="container">*/}
          {/*    <div className="row">*/}
          {/*      <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">*/}
          {/*        <div className="eu-content">*/}
          {/*        <h3 className="title">Title</h3>*/}
          {/*        <h3 className="title-2">Title-2</h3>*/}
          {/*        <p className="text">text</p>*/}
          {/*      </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}

          <footer>
            <div className="footer-top-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12">
                    <div className="footer-widget">
                      <h2>InStar Energy Lab S.A.</h2>
                      <p className="text">
                        ul. Sienna 86/47 00-815 Warszawa
                      </p>

                      <h3>Company details</h3>
                      <p className="text">
                        NIP: 7010638914, REGON: 366020312
                        <br />
                        KRS: 0000803322 Sąd Rejonowy Warszawa XII Wydział
                        Gospodarczy
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-3 col-12"></div>

                  <div className="col-lg-4 col-md-5 col-12">
                    <div className="footer-widget">
                      <h3 className="title-2">Follow Us</h3>
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <ul className="social">
                            <li>
                              <a href="https://www.facebook.com/InStarEnergyLab/">
                                <span className="ico">
                                  <i className="fab fa-facebook-f"></i>
                                </span>{' '}
                                Facebook
                              </a>
                            </li>
                            <li>
                              <a href="https://www.linkedin.com/company/instar-energy-lab-s-a">
                                <span className="ico">
                                  <i className="fab fa-linkedin-in"></i>
                                </span>{' '}
                                Linkedin
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className="eu-area" id="_eu">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="eu-content">
                      <h2 className="title">Informacja o projekcie</h2>
                      <h3 className="title-2">
                        Instar Energy Lab S.A. realizuje projekt dofinansowany z
                        Funduszy Europejskich pn: „Stworzenie centrum badawczo
                        rozwojowego w obszarze zastosowań technologii Blockchain
                        w energetyce”
                      </h3>
                      <p className="text">
                        Celem projektu jest utworzenie nowego centrum
                        badawczo-rozwojowego, w którym będzie tworzony i
                        testowany innowacyjny produkt składający się z:
                      </p>
                      <ol>
                        <li>
                          Instalacji układów obliczeniowych dla technologii
                          Blockchain,
                        </li>
                        <li>
                          Baterii akumulatorów spełniających jednocześnie rolę
                          awaryjnego źródła zasilania i elementu kompensującego
                          odchylenia w profilu poboru mocy przez instalację
                          (minimalizacja kosztów bilansowania),
                        </li>
                        <li>
                          Oprogramowania do zarządzania pracą instalacji w
                          sposób optymalny energetycznie (dla polskiego rynku
                          energii) z uwzględnieniem potrzeb mechanizmu
                          interwencyjnego programu DSR. Wśród najważniejszych
                          rezultatów projektu wymienić można wzrost nakładów
                          inwestycyjnych oraz na działalność badawczo –
                          rozwojową, a także wzrost zatrudnienia o 2,5 EPC.
                        </li>
                      </ol>
                      <p className="text">
                        Wartość projektu: 11 268 522,00 PLN. 
                      </p>
                      <p className="text">Koszty kwalifikowane: 9 161 400,00 PLN. </p>
                      <p className="text">Dofinansowanie projektu z UE: 6 367 980,00 PLN
                      </p>
                      <p className="text">&nbsp;</p>

                      <div
                        className="eu-content row d-flex justify-content-around"
                        style={{ maxHeight: '200px' }}
                      >
                        <img
                          src="../img/FE_IR_rgb-1.jpg"
                          alt=""
                          className="align-self-center"
                        />
                      </div>

                      <p className="text">&nbsp;</p>
                      <h3 className="title-2">
                        Instar Energy Lab S.A. realizuje projekt dofinansowany z
                        Funduszy Europejskich "Opracowanie innowacyjnego algorytmu pozwalającego na optymalizację energetyczną i operacyjną w czasie rzeczywistym usługi centrum obliczeniowego Blockchain”
                      </h3>
                      <p className="text">
                      Przedmiotem projektu jest przeprowadzenie prac badawczo-rozwojowych celem opracowania innowacyjnego algorytmu opartego o uczenie maszynowe pozwalającego na unowocześnienie usługi centrum obliczeniowego Blockchain.
                      </p>
                      <p className="text">
                      Celem projektu jest podniesienie jakości świadczonych usług, znaczące ulepszenie sposobu przetwarzania zebranych danych, zmniejszenie kosztów oraz wyraźne zwiększenie efektywności CBR.
                      </p>
                      <p className="text">
                      Projekt jest współfinansowany w ramach Europejskiego Funduszu Rozwoju Regionalnego ze środków Programu Operacyjnego Inteligentny Rozwój 2014-2020.
                      </p>
                      <p className="text">
                      Oś priorytetowa II: Wsparcie otoczenia i potencjału przedsiębiorstw do prowadzenia działalności B+R+I Działanie 2.3 Proinnowacyjne usługi dla przedsiębiorstw Poddziałanie 2.3.2 Bony na innowacje dla MŚP. 
                      </p>
                      <p className="text">
                      Beneficjent: INSTAR ENERGY LAB SPÓŁKA AKCYJNA
                      </p>
                      <p className="text">
                      Całkowita wartość projektu: 491 468, 03 zł.
                      </p>
                      <p className="text">
                      Wartość dofinansowania: 339 632, 37 zł.
                      </p>
                      <p className="text">
                      Okres realizacji: marzec 2021 – marzec 2022
                      </p>
                      <p className="text">&nbsp;</p>
                      <p className="text">&nbsp;</p>
                    </div>
                  </div>
                </div>
                <div
                  className="eu-content row d-flex justify-content-around"
                  style={{ maxHeight: '200px' }}
                >
                  <img
                    src="../img/FE_POIR_poziom_pl-1_rgb.jpg"
                    alt=""
                    className="align-self-center"
                  />
                </div>
              </div>
            </section>
          </footer>
        </div>
      </Layout>
    )
  }
}

export default Main
